<template>
    <section class="topics">
        <div class="topics__wrapper">
            <h2
                v-if="$slots.title"
                class="topics__title"
            >
                <slot name="title" />
            </h2>
            <p
                v-if="$slots.description"
                class="topics__description"
            >
                <slot name="description" />
            </p>

            <div
                v-if="topics.length"
                class="topics__pills"
            >
                <BasePill
                    v-for="topic in topics"
                    :key="topic.uri"
                    :class="pillsClass"
                    :element="NuxtLink"
                    :to="`/${topic.uri}`"
                >
                    {{ topic.title }}
                </BasePill>
            </div>
        </div>
    </section>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
</script>

<script>
export default {
    props: {
        topics: {
            type: Array,
            required: false,
            default: () => []
        },
        pillsClass: {
            type: String,
            required: false,
            default: 'pill--square'
        }
    }
};
</script>

<style lang="less">
.topics {
    &:not(.topics--wide) {
        .grid-container(true, var(--grid-maxWidth-page));
    }
}

.topics__wrapper {
    display: grid;
    gap: var(--spacing-md);
    margin-top: var(--spacing-section);

    @media @q-lg-min {
        max-width: 66%;
    }
}

.topics__title {
    .typography-h3;
    margin: 0;
}

.topics__description {
    margin: 0;
}

.topics__pills {
    display: flex;
    --gap: calc(var(--spacing-sm) / 2);
    flex-wrap: wrap;
    margin: calc(var(--spacing-md) - var(--gap)) calc(var(--gap) * -1);

    .pill {
        margin: var(--gap);
    }
}

.topics--wide {
    .topics__wrapper {
        max-width: none;
        margin-top: 0;
    }
}
</style>
