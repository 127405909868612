<template>
    <main class="layout__main layout__main--no-top-padding">
        <BaseHero
            :image="image"
            :placeholder="pending"
        >
            <template #text>
                <!-- eslint-disable vue/no-v-html -->
                <h2
                    v-if="home && home.headerText"
                    data-allow-anchor
                    v-html="home.headerText"
                />
                <!-- eslint-enable vue/no-v-html -->
            </template>

            <template #banner>
                <BaseUpcoming
                    v-if="home && home.highlight.length"
                    :uri="home.highlight[0].entry.length ? home.highlight[0].entry[0].uri : ''"
                >
                    <template #title>{{ home.highlight[0].title }}</template>
                    <template #default>{{ home.highlight[0].text }}</template>
                    <template
                        v-if="home.highlight[0].entry.length"
                        #meta
                    >
                        {{ formatHighlightDate(home.highlight[0].entry[0]) }}
                    </template>
                </BaseUpcoming>
            </template>
        </BaseHero>

        <article
            class="bleed"
        >
            <BaseTopics
                v-if="home && home.selectedTopics.length"
                :topics="home.selectedTopics[0].topics"
            >
                <template #title>
                    {{ home.selectedTopics[0].title }}
                </template>
                <template #description>
                    {{ home.selectedTopics[0].description }}
                </template>
            </BaseTopics>

            <FlexibleSections
                :sections="home?.sections && Array.isArray(home.sections) ? home.sections : []"
                :placeholder="pending ? 'overview' : undefined"
                data-wide
            />
        </article>
    </main>
</template>

<script setup lang="ts">
import HomeQuery from '~/graphql/queries/Home.graphql';
import usePageQuery from '~/composables/usePageQuery';

const { variables } = useCraftGraphql();
delete variables.uri; // URI not relevant here.

const response = await useLazyAsyncQuery({
    query: HomeQuery,
    variables
});

const {
    craftEntry: home,
    headerImage,
    pending,
} = usePageQuery(
    response,
    { entryKey: 'home' }
);

const image = computed(() => ({
    small: headerImage?.value?.small,
    medium: headerImage?.value?.medium,
    large: headerImage?.value?.large,
    largest: headerImage?.value?.largest
}));

function formatHighlightDate(highlight: any) {
    if (!highlight) {
        return;
    }

    // Prefer dateRange
    if (highlight.dateRange) {
        return useDate().formatDateRange(highlight.dateRange.start, highlight.dateRange.end);
    }

    // Fall back to postdate
    return useDate().formatDateRange(highlight.postDate, highlight.postDate);
}
</script>
